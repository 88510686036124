import React from "react";
import ProductTemplate from "./product";
// import { FiArrowRightCircle } from "react-icons/fi";
// import { Link } from "gatsby";
// import Image from "@components/common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
// import { InlineWidget } from "react-calendly";
// import { FaParking } from "react-icons/fa";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            {/** Qué es¿? */}
            <div className="container lg:px-0 py-20">
                <Title>120 Casos d'Entrevista Policial</Title>
                <Text>
                    <strong>120 casos d'entrevista policial</strong> pretén ser una gran ajuda per a
                    totes aquelles persones que acudeixen a una entrevista per accedir a un cos
                    policial, especialment pel que fa a la resolució de casos pràctics.
                    <br />
                    <br />
                    Durant l'entrevista no ens és favorable el fet de quedar-nos en blanc, dubtar o
                    trigar excessivament a plantejar la nostra proposta de resolució. Per evitar
                    aquest tipus de situacions, en aquestes pàgines teniu a la vostra disposició la
                    resolució de 120 casos pràctics OFICIALS, preguntats en entrevistes per accedir
                    a policies locals de Catalunya, inclosos els roleplay més habituals, tan temuts
                    per alguns opositors.
                    <br />
                    <br />
                    També trobareu algun cas pràctic oficial d'entrevista per accedir al cos de
                    Mossos d ́ Esquadra i casos que han demanat resoldre per escrit en oposicions a
                    policies locals.
                    <br />
                    <br />
                    Ordenats per temàtiques, després de la seva lectura, a més d'aprendre
                    coneixements policials i legislatius, aconseguireu tenir a la vostra disposició
                    un ampli ventall de recursos i eines per contrarestar totes aquelles dificultats
                    que ens plantegin en un cas pràctic d'entrevista, al bulevard de l'ISPC o al
                    vostre dia a dia com a agents.
                    <br />
                    <br />
                    <strong className="text-ccyan">
                        El llibre consta de 304 pàgines a color amb fotografies i té una mida de
                        170x240 mm.
                    </strong>
                </Text>

                {/** Preview del libro */}
                <div className="container lg:px-0 py-20">
                    <Title>Preview del llibre</Title>
                    <Text className="text-justify">
                        A continuación us adjuntem una vista prèvia del llibre en format PDF.
                    </Text>

                    <div className="rounded p-5 border shadow-md mt-5">
                        <embed
                            src={require("@static/documents/products/561/preview.pdf")}
                            type="application/pdf"
                            className=""
                            width="100%"
                            height="750px"
                        ></embed>
                    </div>
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
